import React from "react";

const StarBackground = () => {
  const starCount = 70; // Número de estrellas
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-gradient-to-b from-blue-900 to-black z-[-1]">
      {[...Array(starCount)].map((_, index) => (
        <div
          key={index}
          className="w-1 h-1 bg-white rounded-full absolute animate-float"
          style={{
            animationDelay: `${Math.random() * 5 + 5}s`,
            top: `${Math.floor(Math.random() * 100)}%`,
            left: `${Math.floor(Math.random() * 100)}%`,
          }}
        />
      ))}
    </div>
  );
};

export default StarBackground;
