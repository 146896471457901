import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import StarBackground from "./components/StarBackground"; // Asegúrate de importar correctamente el componente

const Login = lazy(() => import("./components/Login"));
const Register = lazy(() => import("./components/Register"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const AddPlanForm = lazy(() => import("./components/AddPlanForm"));

function App() {
  return (
    <Router>
      <StarBackground /> {/* Fondo de estrellas en todas las páginas */}
      <Suspense fallback={<div>Cargando...</div>}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/add-plan" element={<AddPlanForm />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="*" element={<div>404 - Página no encontrada</div>} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
